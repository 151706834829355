import React from "react"
import LocationCard from "./locationCard"
import {Link} from "gatsby"
import BlockContent from "@sanity/block-content-to-react";


export default function Location({nodes}) {

  const posts = nodes && nodes[0].posts

  const {title, subTitle, _rawText} = nodes[0]

  return (
    <section id="location" className="container section">

    <div className="row">
      <div className="col-12 col-lg-5">
        <span className="title title--overhead js-lines">{subTitle}</span>
        <h1 className="title title--h1 js-lines">{title}</h1>
    </div>
  <div className="col-12 col-lg-6 offset-lg-1 offset-top">
    {/* <p className="paragraph js-scroll-show">{text}</p> */}
    <BlockContent blocks={_rawText} className="paragraph js-scroll-show" renderContainerOnSingleChild={true}/>
  </div>

  <div className="col-12 col-md-12 col-lg-12 text-lg-left d-none d-md-block">
  <br/>
    {/* <Link to="/localarea/" className="btn-link header-btn-more">See all local attractions</Link> */}
</div>

</div>

      <div className="row">
      {posts && posts.map((post, i) => (
            <LocationCard key={i} {...post} previewSize={post.previewSize} />
          ))}
            </div>
</section>
  )
}