import React from "react"
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import BlockContent from "@sanity/block-content-to-react";

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

export default function Testimonials({testimonials, title, subTitle}) {
  return (
		<section id="guests" className="section section-testimonials section-gray mb-5">
			<div className="container">
				<div className="row">
					<div className="col-12">
								<span className="title title--overhead js-lines">{subTitle}</span>
								<h1 className="title title--h1 js-lines" style={{maxWidth: 550 + 'px'}} >{title}</h1>
						</div>
				
				<div className="col-12">
				
        <Swiper
        spaceBetween={20}
        pagination={{ clickable: true }}
        onSwiper={(swiper) => console.log(swiper)}
        onSlideChange={() => console.log('slide change')}
        breakpoints={{
          // when window width is >= 900px
          900: {
            slidesPerView: 2,
          },
          // when window width is >= 1280px
          1280: {
            slidesPerView: 3,
          },
        }}
        >
          {testimonials && testimonials.map((testimonial, i) => (
							<SwiperSlide key={i} className="testimonials-item">
							  <h4 className="title title--h4">{testimonial.title}</h4>
                {testimonial._rawDescription && (
                  <BlockContent blocks={testimonial._rawDescription} className="testimonials-item__caption" renderContainerOnSingleChild={true}/>
                )}
                <div className="author-wrap">
                  <div>
                      <div className="author-name">{testimonial.guestname}</div>
                      <div className="author-country">{testimonial.platform}</div>
                  </div>
                </div>
							</SwiperSlide>
						  ))}		
  			</Swiper>

				</div>
			</div>
		</div>
			</section>
  )
}