import React, {useState} from "react"
import BlockContent from "@sanity/block-content-to-react";

export default function FloatingButtons({promoTitle, _rawPromoText}) {

  const [showPromo, setShowPromo] = useState(true)
  const onPromoClose = () => setShowPromo(false)

  return (
  <>
    <a href="#"><div className="btn-floating js-show-to-scroll"><i className="icon-arrow-up"></i></div></a>
    <div className={`promo-floating js-show-to-scroll ${!showPromo && "d-none"}`}>
      <button type="button" className="close" onClick={onPromoClose}>
        <span aria-hidden="true">&times;</span>
      </button>
      <h4>{promoTitle}</h4>
      <BlockContent blocks={_rawPromoText} renderContainerOnSingleChild={true}/>
    </div>
  </>
  )
}

