import React from "react"
import BlockContent from "@sanity/block-content-to-react";

export default function Details({title, subTitle, area}) {
  return (
    <section id="details" className="container section">		
					<div className="row mb-4 mb-md-5">
							<div className="col-12">
								<span className="title title--overhead js-lines">{subTitle}</span>
								<h1 className="title title--h1 js-lines">{title}</h1>
						</div>
				</div>


				{area && area.map((place, i) => (
					<div key={i} className="row item-approach" data-approach={i + 1}>
						<div className="col-12 col-lg-6">
							<h3 className="title title--h3 js-lines">{place.title}</h3>
					</div>
					<div className="col-12 col-lg-6">
							{/* <p className="paragraph js-scroll-show">{place.text}</p> */}
							<BlockContent blocks={place._rawText} className="paragraph js-scroll-show" renderContainerOnSingleChild={true}/>
					</div>
				</div>
				))}

			</section>
  )
}