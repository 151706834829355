import React from "react"

export default function Intro({heroLogo, heroBg}) {

  return (
		<section className="intro">
		    <div className="intro__bg-wrap">
		        <div className="overlay intro__bg js-image js-parallax" style={{backgroundImage: `url("${heroBg.asset.fluid.src}")`}}></div>
			</div>
	        <div className="container intro__container">
		        <div className="row h-100 align-items-center">
			        <div className="col-12 col-md-12 col-xl-12 d-flex flex-column align-items-center justify-content-center">
										<img className="intro__logo" src={heroLogo.asset.fluid.src} alt="Atlantic Lookout"/>
					    {/* <button className="intro__btn-play" id="play"><span className="btn-play btn-play__popup"></span></button> */}
				    </div>
                </div>				
		    </div>
		    <a className="scroll-to" href="#about">Scroll<span className="scroll-to__line"></span></a>
	    </section>
  )
}