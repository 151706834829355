import React from "react"
import BlockContent from "@sanity/block-content-to-react";

export default function About({title, subTitle, _rawTextLeft, _rawTextRight, imageLeft, imageRight, features, moreLink}) {
  return (
      <section id="about" className="container section">
	        <div className="row">
		        <div className="col-12 col-md-12 col-lg-8">
			        <span className="title title--overhead js-lines">{subTitle}</span>
			        <h1 className="title title--h1 js-lines">{title}</h1>
			    </div>
				<div className="col-12 col-lg-6 text-left text-sm-justify">
					{/* <p className="mr-0 mr-lg-4 paragraph js-scroll-show">{textLeft}</p> */}
					<BlockContent blocks={_rawTextLeft} className="mr-0 mr-lg-4 paragraph js-scroll-show" renderContainerOnSingleChild={true}/>
				</div>
				<div className="col-12 col-lg-6 text-left text-sm-justify">
					{/* <p className="ml-0 ml-lg-4 paragraph js-scroll-show">{textRight}</p> */}
					<BlockContent blocks={_rawTextRight} className="ml-0 ml-lg-4 paragraph js-scroll-show" renderContainerOnSingleChild={true}/>
				</div>
				
				<div className="col-12 col-lg-6">
				    <figure className="about-image-wrap mr-0 mr-lg-4">
				        <a className="about-link" href={moreLink}>Explore More <i className="icon-arrow-special"></i></a>
				        <img className="cover about-image-portrait" src={imageLeft.asset.fluid.src} alt="about" />
					</figure>	
				</div>
				<div className="col-12 col-lg-6">
				    <figure className="about-image-wrap ml-0 ml-lg-4 mb-5">
					    <img className="cover about-image-landscape" src={imageRight.asset.fluid.src} alt="about" />
					</figure>
					<div className="col-12 mt-6 ml-0 ml-lg-4">
						<ul className="list-unstyled list-feature row">
							{features && features.map((feature, i) => (
								<li key={i} className="list-feature__item  col-6 col-md-6">
									{feature.icon && (
									<img className="icon icon--24" src={feature.icon.asset.fluid.src} alt="" />
									)}
									{feature.title}
								</li>
							))}
						</ul>
				</div>
				</div>
				</div>
					
		</section>
  )
}