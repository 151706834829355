import React, {useEffect} from "react"
import About from "../components/about"
import Booking from "../components/booking"
import Details from "../components/details"
import Gallery from "../components/gallery"
import Intro from "../components/intro"
import Layout from "../components/layout"
import Video from "../components/video"
import Location from "../components/location"
import Testimonials from "../components/testimonials"
import CTA from "../components/cta"
import Lightbox from "../components/lightbox"
import FloatingButtons from "../components/floatingButtons"
import {graphql} from 'gatsby'
import Contact from "../components/contact"

export const query = graphql`
 {
  site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
    siteName
    siteDescription
    ctaTitle
    ctaSubtitle
    bookingLink
    promoBoolean
    promoTitle
    _rawPromoText
    _rawBookingInfo
  }

  hero: sanityHero(_id: { regex: "/(drafts.|)hero/" }) {
    heroLogo {
      asset {
        fluid(maxWidth: 2048) {
          ...GatsbySanityImageFluid_noBase64
        }
      }
    }
    heroBg {
      asset {
        fluid(maxWidth: 2048) {
          ...GatsbySanityImageFluid_noBase64
        }
      }
    }
  }

  about: sanityAbout(_id: { regex: "/(drafts.|)about/" }) {
    title
    subTitle
    _rawTextLeft
    _rawTextRight
    imageLeft {
      asset {
        fluid {
          ...GatsbySanityImageFluid
        }
      }
    }
    imageRight {
      asset {
        fluid {
          ...GatsbySanityImageFluid
        }
      }
    }
    moreLink
    features {
      title
      icon {
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
  }

  gallery: sanityGallery(_id: { regex: "/(drafts.|)gallery/" }) {
    images {
      image {
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
        }
      }
      title
    }
  }

  details: sanityDetails(_id: { regex: "/(drafts.|)details/" }) {
    title
    subTitle
    area {
      title
      _rawText
    }
  }

  video: sanityVideo(_id: { regex: "/(drafts.|)video/" }) {
    videoLink
    videoBg {
      asset {
        fluid {
          ...GatsbySanityImageFluid
        }
      }
    }
  }

  guests: sanityGuests(_id: { regex: "/(drafts.|)guests/" }) {
    title
    subTitle
    testimonials {
      title
      guestname
      _rawDescription
      platform
    }
  }

  allSanityLocal {
    edges {
      node {
        title
        _rawText
        subTitle
        posts {
          previewSize
          post {
            title
            slug {
              current
            }
            category {
              title
            }
            image {
              asset {
                fluid {
                  src
                }
              }
            }
          }
        }
      }
    }
  }
  
  allSanityContact {
    edges {
      node {
        email
        phone
      }
    }
  }
  
  allSanityGallery {
    edges {
      node {
        images {
          image {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
  }
}
`


function mapEdgesToNodes (data) {
  if (!data.edges) return []
  return data.edges.map(edge => edge.node)
}

// markup
const IndexPage = ({data}) => {

  const locationNodes = (data || {}).allSanityLocal
  ? mapEdgesToNodes(data.allSanityLocal)
  : []
  
  const contactNodes = (data || {}).allSanityContact
  ? mapEdgesToNodes(data.allSanityContact)
  : []

  const galleryNodes = (data || {}).allSanityGallery
  ? mapEdgesToNodes(data.allSanityGallery)
  : []

  const testimonialNodes = (data || {}).allSanityTestimonials
  ? mapEdgesToNodes(data.allSanityTestimonials)
  : []
  
  const site = (data || {}).site
  const hero = (data || {}).hero
  const about = (data || {}).about
  const gallery = (data || {}).gallery
  const details = (data || {}).details
  const video = (data || {}).video
  const guests = (data || {}).guests

useEffect(() => {
  let targetElement = document.querySelectorAll('.js-show-to-scroll'),
          showBackTotop = 100; 
          
          targetElement.forEach((element, i) => {
            element.classList.add('animated')
            element.classList.add('fadeOutDown')
          })
          
          window.addEventListener('scroll', function() {
          var windowScrollTop = window.scrollY;
          if( windowScrollTop > showBackTotop ) {
      //$targetElement.fadeIn('800, swing');
      targetElement.forEach((element, i) => {
        element.classList.add('fadeInUp')
        element.classList.remove('fadeOutDown')

      })
          } else {
      //$targetElement.fadeOut('500, swing');
      targetElement.forEach((element, i) => {
        element.classList.remove('fadeInUp')
        element.classList.add('fadeOutDown')
      })
          }
          
      targetElement.forEach((element, i) => {
        element.classList.remove('is-bottom')
      })   
    if(window.innerHeight + window.scrollY >= document.body.offsetHeight) {
      targetElement.forEach((element, i) => {
        element.classList.add('is-bottom')
      }) 
          }
      });
}, [])


  return (
    <Layout>
      <Intro {...hero}/>
      <Booking {...site}/>
      <About  {...about}/>

      <Details {...details}/>
      <Gallery {...gallery}/>
      {/* <Video {...video}/> */}
      <Location nodes={locationNodes}/>
      <Testimonials {...guests}/>
      <CTA {...site}/>
      <Contact nodes={contactNodes}/>
      {/* <Lightbox {...hero}/> */}
      {site.promoBoolean && (<FloatingButtons {...site}/>)}
    </Layout>
  )
}

export default IndexPage
