import React from "react"

export default function CTA({ctaTitle, ctaSubtitle, bookingLink}) {
  return (
    <section className="container">
      <div className="row cta-box cta-negative js-scroll-show">
        <div className="col-12 col-lg-8">
            <h2 className="title title--h2">{ctaTitle}</h2>
          <p className="paragraph">{ctaSubtitle}</p>
        </div>
        <div className="col-12 col-lg-4 text-lg-right">
            <a href={bookingLink} className="btn btn__large btn--white">Book Now <i className="btn-icon-right icon-arrow-special"></i></a>
        </div>
      </div>
    </section>
  )
}