import React from "react"
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

export default function Gallery({images}) {


  return (
    <section className=" section" id="gallery">
			<div className="">
        <Swiper
        spaceBetween={20}
        slidesPerView={1}
        pagination={{ clickable: true }}
        onSwiper={(swiper) => console.log(swiper)}
        onSlideChange={() => console.log('slide change')}
        breakpoints={{
          // when window width is >= 900px
          900: {
            slidesPerView: 2,
          },
          // when window width is >= 1280px
          1280: {
            slidesPerView: 3,
          },
        }}
        >
    			{images && images.map((image, i) => (
    				<SwiperSlide key={i}><img src={image.image.asset.fluid.src} alt="" style={{objectFit: "cover"}}/></SwiperSlide>
    			))}
  			</Swiper>
			</div>
</section>
  )
}