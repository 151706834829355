import { Link } from "gatsby"
import React from "react"

export default function LocationCard({post, previewSize}) {

  const {title, category, image, slug} = post

  return (
    <div className={`col-12 col-md-12 col-lg-${previewSize}`}>
      {category && (
        <div className="itemRoom">
          <span className="badge">{category.title}</span>
          <figure className="itemRoom__img-wrap">
            <Link to={`${slug.current}`} className="itemRoom__link">
              <img className="cover" src={image.asset.fluid.src} alt="room" />
          </Link>
        </figure>
          <div className="itemRoom__details">
              <h4 className="title title--h4">{title}</h4>

          </div>	
        </div>
      )}
</div>
  )
}