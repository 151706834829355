import React from "react"

export default function Contact({ nodes }) {

  const {email, phone} = nodes[0]
  
  return (
    <section className="section container">
      <div class="row h-100 align-items-center">
				    <div class="col-12">
					    <div class="row ">
                <div class="col-12 mb-2 mb-sm-4 text-center">
							    <div class="bottom-panel-title">Email:</div>
								  <a href={`mailto:${email}`}><span class="bottom-panel-info">{email}</span></a>
							</div>
				            <div class="col-12 mb-2 mb-sm4 text-center">
							    <div class="bottom-panel-title">Phone:</div>
                  <a href={`tel:${phone}`}><span class="bottom-panel-info">{phone}</span></a>
							</div>
						</div>
					</div>
				</div>
    </section>
  )
}