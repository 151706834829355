import React, {useState, useEffect} from "react"
import Flatpickr from "react-flatpickr";
import Modal from 'react-modal';
import BlockContent from "@sanity/block-content-to-react";

const customStyles = {
  content : {
    top                   : '1rem',
    left                  : '1rem',
    right                 : '1rem',
    bottom                : 'auto',
    zIndex                : '99999',
    boxShadow             : '0px 48px 64px rgb(44 63 88 / 10%)',
    border                : 'none',
    maxWidth              : '800px'
  }
};

Modal.setAppElement('#___gatsby')

export default function Booking({_rawBookingInfo}) {
	
  const [dateFrom, setDateFrom ] = useState(new Date())
	const [dateTo, setDateTo] = useState(new Date(dateFrom.getTime() + (24 * 60 * 60 * 1000)))
	const [stayLength, setStayLength] = useState(0)
  const [dateFromToSend, setDateFromToSend ] = useState()
	const [dateToToSend, setDateToToSend] = useState()
	
	const [modalIsOpen,setIsOpen] = React.useState(false);
	
	useEffect(() => {
    setDateTo(new Date(dateFrom.getTime() + (24 * 60 * 60 * 1000)))
  }, [dateFrom])

useEffect(() => {
  
  let difference = daysBetween(dateFrom, dateTo);

  function daysBetween( date1, date2 ) {
    var one_day=1000*60*60*24;
    var date1_ms = date1.getTime();
    var date2_ms = date2.getTime();
    var difference_ms = date2_ms - date1_ms;
    return Math.round(difference_ms/one_day);
  }
  
  setStayLength(difference)
  
  function updateDatesToSend(checkInDate, checkOutDate) {
    var dd0 = checkInDate.getDate();
    var mm0 = checkInDate.getMonth() + 1;
    var yyyy0 = checkInDate.getFullYear();
    if ( dd0 < 10 ) dd0 = '0' + dd0;
    if ( mm0 < 10 ) mm0 = '0' + mm0;
    setDateFromToSend(yyyy0 + '-' + mm0 + '-' + dd0);

    var dd = checkOutDate.getDate();
    var mm = checkOutDate.getMonth() + 1;
    var yyyy = checkOutDate.getFullYear();
    if ( dd < 10 ) dd = '0' + dd;
    if ( mm < 10 ) mm = '0' + mm;
    setDateToToSend(yyyy + '-' + mm + '-' + dd);
}

updateDatesToSend(dateFrom, dateTo);

}, [dateTo, dateFrom])

function openModal(e) {
e.preventDefault()
  setIsOpen(true);
}

function afterOpenModal() {
  // references are now sync'd and can be accessed.

}

function closeModal(){
  setIsOpen(false);
}

  return (
<div className="bottom-panel booking-panel">
		    <form className="bottom-panel__wrap d-flex" target="_blank" action="https://portal.freetobook.com/reservations">
						<input type="hidden" name="w_id" value="34758"/>
            <input type="hidden" name="w_tkn" value="I7vnZm1q7kWAaksRmwaruwkVLerRKQnwn4wSbymkXPAITmmmIKdPxRXTnuvwb"/>
            <input type="hidden" id="check-in-date-to-send" name="check_in_date" value={dateFromToSend}/>
            <input type="hidden" id="check-out-date-to-send" name="check_out_date" value={dateToToSend}/>
            <input id="stay-length" type="hidden" name="stay_length" value={stayLength}></input>
			    <div className="row bottom-panel__form-wrap">
              <div className="form-group col-12 col-md-4 slash">
                <label className="labelFeature" htmlFor="check-in">Information</label>
                <button class="bookingInfo__button" onClick={(e) => openModal(e)}>View Booking info</button>
              </div>
			        <div className="form-group col-date-to col-12 col-sm-6 col-md-4 slash">
				        <label className="labelFeature" htmlFor="check-in">Check in</label>
								<Flatpickr
									options={{
										dateFormat: 'd M Y',
										minDate: "today"
									}}
									placeholder={"Select date"}
									value={dateFrom}
									onChange={dateFrom => {
										setDateFrom(new Date(dateFrom));
									}}
									id="check-in-date"
									className="inputFeature input-arrow readonly js-datepicker"
								/>
						</div>
				    <div className="form-group col-date-from col-12 col-sm-6 col-md-4">
				        <label className="labelFeature" htmlFor="check-out">Check out</label>
                <Flatpickr
									options={{
										dateFormat: 'd M Y',
										minDate: "today"
									}}
									placeholder={"Select date"}
									value={dateTo}
									onChange={dateTo => {
										setDateTo(new Date(dateTo));
									}}
									id="check-out-date"
									className="inputFeature input-arrow readonly js-datepicker"
								/>
				    </div>
				    {/* <div className="form-group col-12 col-md-4">
					    <div className="closeDropdown" id="dropdownPersonsAction" data-toggle="dropdown" data-display="static">
				            <label className="labelFeature" htmlFor="person-total">Guests (Max 6)</label>
										<input type="number" className="inputFeature" id="person-total" name="person" placeholder="Number of guests" required="required" autoComplete="off" min="1" max="6"/>
						</div>
				    </div> */}
				</div>
		        <button type="submit" className="btn-booking"><span>Next</span>Book Now</button>
			</form>
			<Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <button type="button" class="modal__close close" onClick={closeModal}><span aria-hidden="true">×</span></button>
          <BlockContent blocks={_rawBookingInfo} renderContainerOnSingleChild={true}/>
          <button class="btn-booking" style={{margin: "0 auto"}} onClick={closeModal}>close</button>
        </Modal>
		</div>
  )
}